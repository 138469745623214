import {Banner, Dimensions} from "../types/Banner.model";
import {BannerHelper} from "../helpers/BannerHelper";
import {CMPService} from "../../services/CMPService";

export default class GamBannerService {
    private banners: Banner[];

    constructor() {
        window.lwhb = window.lwhb || {cmd: []};
        window.googletag = window.googletag || {cmd: []};
        window.jppolWallpaper = function () {
            document.getElementsByClassName('c-article-halfpage')[0].classList.add('wallpaperPosition');
            document.getElementsByClassName('c-article-halfpage')[1].classList.add('wallpaperPosition');
        };
    }

    public initialize(): void {
        this.banners = BannerHelper.transformRawBanners(window.bannersData || {desktopBanners: {}, mobileBanners: {}});
        CMPService.checkOtherCookiesConsent().then(() => {
            BannerHelper.setupGoogleTagEventHandling();
            if (!window.livewrappedIsDown) {
                if (window.programmaticTopscroll) {
                    this.defineLwBanners(this.banners);
                } else {
                    const [bannersWithoutTopscroll, topscrollBanners] = this.banners.reduce((accumulatedResult, banner) => {
                        const bucketIndex = banner.livewrappedId.toLowerCase().includes("topscroll") ? 1 : 0;
                        accumulatedResult[bucketIndex].push(banner);
                        return accumulatedResult;
                    }, [[], []])
                    this.defineGoogleBanner(topscrollBanners);
                    this.defineLwBanners(bannersWithoutTopscroll);
                }
            } else {
                window.lwhb.disablePrebid = true;
                this.defineGoogleBanner(this.banners);
            }
        }).catch(() => {
            BannerHelper.setupGoogleTagEventHandling();
            if (!window.livewrappedIsDown) {
                this.defineLwBanners(this.banners)
            } else {
                this.defineGoogleBanner(this.banners);
            }
        });
    }

    private defineGoogleBanner(banners: Banner[]) {
        this.getKeywords();
        window.googletag.cmd.push(() => {
            banners.forEach((banner: Banner) => {
                let element = document.getElementById(banner.livewrappedId);
                if (banner.livewrappedId.includes("halfpage")) {
                    if (GamBannerService.anyBannerHasWidth300(banner.sizes)) {
                        element = document.querySelector('[data-banner-type$="half-page-300"] #' + banner.livewrappedId);
                    }
                }
                const parent = element?.parentElement;
                const innerWidth = (window as Window).innerWidth;
                const halfpageOuterParent = parent?.parentElement;
                const dataBannerType = parent?.getAttribute('data-banner-type');

                window.googletag.pubads().disableInitialLoad();
                if (BannerHelper.shouldBannerBeShown(innerWidth, halfpageOuterParent?.offsetHeight, dataBannerType)) {
                    window.googletag.defineSlot(banner.adUnitPath, GamBannerService.asNumberPairArray(banner.sizes), banner.livewrappedId).addService(window.googletag.pubads());
                    window.googletag.display(banner.livewrappedId);
                } else {
                    parent?.remove();
                }
            });
            window.googletag.pubads().enableSingleRequest();
            window.googletag.pubads().enableLazyLoad();
            window.googletag.enableServices();
            window.googletag.pubads().refresh();

            window.addEventListener('pageshow', (event) => {
                // Refresh all banners if the page is restored from bfcache.
                if (event.persisted) {
                    window.googletag.pubads().refresh();
                }
            });
        })
    }

    private defineLwBanners(banners: Banner[]): void {
        this.getKeywords();
        banners.forEach((banner: Banner) => {
            let element = document.getElementById(banner.livewrappedId);
            if (banner.livewrappedId.includes("halfpage")) {
                if (GamBannerService.anyBannerHasWidth300(banner.sizes)) {
                    element = document.querySelector('[data-banner-type$="half-page-300"] #' + banner.livewrappedId);
                }
            }
            const parent = element?.parentElement;
            const innerWidth = (window as Window).innerWidth;
            const halfpageOuterParent = parent?.parentElement;
            const dataBannerType = parent?.getAttribute('data-banner-type');
            if (BannerHelper.shouldBannerBeShown(innerWidth, halfpageOuterParent?.offsetHeight, dataBannerType)) {
                window.lwhb.cmd.push(() => {
                    if (banner.placement.toLowerCase().includes("small")) {
                        window.lwhb.loadAd({tagId: banner.livewrappedId, sizes: [[160, 600]], gamSizes: [[160, 600]]});
                    } else if (banner.placement.toLowerCase().includes("halftakeover")) {
                        window.lwhb.loadAd({tagId: banner.livewrappedId, sizes: [[320, 160]], gamSizes: [[320, 160]]});
                    } else {
                        window.lwhb.loadAd({tagId: banner.livewrappedId});
                    }
                });
            } else {
                parent?.remove();
            }
        });
    }

    private getKeywords() {
        const publicationKey = window.bannerKeywords.publicationKey;
        window.googletag.cmd.push(() => {
            window.googletag.pubads()
                .setTargeting("userType", window.bannerKeywords.userType)
                .setTargeting("Relevance_Audiences", window.bannerKeywords.relevanceAudiences)
                .setTargeting("Relevance_Context", window.bannerKeywords.relevanceContext)
                .setTargeting(publicationKey, window.bannerKeywords.publicationValues)
                .setPublisherProvidedId(window.commonJpData.anonId)
        });

        const data = {
            [publicationKey]: window.bannerKeywords.publicationValues,
            "Relevance_Audiences": window.bannerKeywords.relevanceAudiences,
            "Relevance_Context": window.bannerKeywords.relevanceContext,
        }
        const keywordsString = Object.entries(data)
            .flatMap(([key, values]) =>
                values.length > 0
                    ? values.map((value) => `${key}=${value}`)
                    : [`${key}=`]
            )
            .join(",")

        window.lwhb.cmd.push(() => {
            window.lwhb.ortb2({
                "site": {
                    "ext": {
                        data,
                    },
                    "keywords": keywordsString
                }
            })
            window.lwhb.csKeyValues({"pubProvidedId": window.commonJpData.anonId});
        })
    }

    private static anyBannerHasWidth300(sizes: Dimensions[]): boolean {
        return -1 !== sizes.findIndex(rectangle => rectangle.width == 300);
    }

    private static asNumberPairArray(sizes: Dimensions[]): [number, number][] {
        return sizes.map(rectangle => [rectangle.width, rectangle.height]);
    }

}
